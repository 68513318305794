import React, { useState, memo, forwardRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CreditCard, X } from "@phosphor-icons/react";
import { MODE } from "../../lib/constants/api";

// Types
interface CardData {
  title: string;
  number: string;
}

interface CardDataStructure {
  success: CardData;
  failure: CardData;
  common: {
    expiry: string;
    cvv: string;
  };
}

interface TabIndicatorProps {
  active: boolean;
}

interface CardDetailsProps {
  isFailureMode: boolean;
}

interface DialogContentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

// Constants
const CARD_DATA: CardDataStructure = {
  success: {
    title: "Success",
    number: "4242 4242 4242 4242",
  },
  failure: {
    title: "Failure",
    number: "4000 0000 0000 0002",
  },
  common: {
    expiry: "06/32",
    cvv: "123",
  },
};

// Memoized sub-components
const TabIndicator: React.FC<TabIndicatorProps> = memo(({ active }) =>
  active ? (
    <div className="w-full h-1 bg-[#c6fe1e] absolute -bottom-[3px]" />
  ) : null
);
TabIndicator.displayName = "TabIndicator";

const CardDetails: React.FC<CardDetailsProps> = memo(({ isFailureMode }) => {
  const data = isFailureMode ? CARD_DATA.failure : CARD_DATA.success;

  return (
    <div className="w-[561px] h-[355px] max-w-[80vw] rounded-[30px] relative overflow-hidden border border-border-primary flex flex-col justify-between p-9">
    <img 
      src="/card.png" 
      alt="Card Background" 
      className="absolute inset-0 w-full h-full object-cover rounded-[30px] -z-10"
    />
    <div className="flex items-center justify-end">
      <span className="text-white font-bold text-lg uppercase">
        {data.title}
      </span>
    </div>
    <div className="flex flex-col gap-4 w-full">
      <span className="text-white text-lg md:text-2xl uppercase font-bold">
        {data.number}
      </span>
      <span className="h-[1px] bg-border-primary" />
      <div className="w-full flex items-center justify-between">
        <span className="text-white text-lg md:text-xl uppercase font-medium">
          EXPIRY : {CARD_DATA.common.expiry}
        </span>
        <span className="text-white text-lg md:text-xl uppercase font-medium">
          CVV : {CARD_DATA.common.cvv}
        </span>
      </div>
    </div>
  </div>
  
  );
});
CardDetails.displayName = "CardDetails";

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(({ open, setOpen }, ref) => (
    <Dialog.Content ref={ref} className="fixed top-1/2 left-1/2 max-w-[90vw] transform -translate-x-1/2 -translate-y-1/2 bg-background-primary border border-border-primary p-6 rounded-xl shadow-lg">
      <Dialog.Title className="text-base font-display gap-2 font-medium text-text-primary flex flex-col">
        <div className="flex items-center w-full justify-between">
          <div className="rounded-full w-fit p-3 bg-background-secondary text-text-primary">
            <CreditCard className="w-7 h-7" />
          </div>
            <Dialog.Close className="text-text-primary text-sm cursor-pointer w-fit"><X/></Dialog.Close>
        </div>
        Test Cards
      </Dialog.Title>
      <Dialog.Description className="mt-2 text-sm text-text-secondary">
        Use these card details to simulate payment success and failures
      </Dialog.Description>
      <section className="flex flex-col mt-4 gap-4 items-center">
        <div className="flex my-2 mt-0 items-center  font-display text-base border-b-2 border-border-primary w-fit gap-4">
          <span
            className="relative text-text-primary pb-1 cursor-pointer"
            onClick={() => setOpen(false)}
          >
            Payment Success
            <TabIndicator active={!open} />
          </span>
          <span
            className="relative text-text-primary pb-1 cursor-pointer"
            onClick={() => setOpen(true)}
          >
            Payment Failure
            <TabIndicator active={open} />
          </span>
        </div>
        <CardDetails isFailureMode={open} />
      </section>
    </Dialog.Content>
  )
);
DialogContent.displayName = "DialogContent";

const TestCard: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  if (!MODE) return null;
  return (
    <section className="w-full mt-6 border flex-col md:flex-row border-border-primary rounded-xl p-3 md:p-6 flex items-center justify-between">
      <div className="flex items-center justify-start w-full md:w-fit gap-2">
        <div className="rounded-full p-3 border border-border-primary text-text-primary">
          <CreditCard className="w-7 h-7" />
        </div>
        <div className="flex flex-col text-sm max-w-[260px]">
          <span className="text-text-primary font-medium">Test Cards</span>
          <span className="text-text-secondary leading-tight text-sm">
            Use these card details to simulate payment success and failures
          </span>
        </div>
      </div>

      <Dialog.Root>
        <Dialog.Trigger className="md:w-fit w-full justify-end flex">
          <div className="font-semibold w font-display text-text-primary">
            View Test Cards
          </div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-50" />
          <DialogContent open={open} setOpen={setOpen} />
        </Dialog.Portal>
      </Dialog.Root>
    </section>
  );
};

export default TestCard;
