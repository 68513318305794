export const getThemeVariables = (theme) => ({
  colorTextSecondary: theme === "dark" ? "#909090" : "#6B7280",
  colorBackground: theme === "dark" ? "#0D0D0D" : "#FFFFFF",
  colorText: theme === "dark" ? "#F9FAFB" : "#344054",
  colorDanger: theme === "dark" ? "#FCA5A5" : "#F04438",
  colorWarningText: theme === "dark" ? "#ff4545" : "#ff4545",
  colorBackgroundText: theme === "dark" ? "#909090" : "#0D0D0D",
  colorTextPlaceholder: theme === "dark" ? "#9CA3AF" : "#6B7280",
});

export const getAppearanceConfig = (theme) => {
  const themeVariables = getThemeVariables(theme);

  return {
    theme: "none",
    variables: {
      fontFamily: "'Inter', sans-serif",
      fontSizeBase: "16px",
      ...themeVariables,
    },
    rules: {
      ".TabMore":{
        color: theme === "dark" ? "#D1D5DB" : "#344054",
      },
      ".Input": {
        border: theme === "dark" ? "1px solid #323232" : "1px solid #D0D5DD",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        background: "#0D0D0D",
        backgroundColor: theme === "dark" ? "#0D0D0D" : "#FFFFFF",
        color: theme === "dark" ? "#FFFFFF" : "#344054",
      },
      ".Label": {
        color: theme === "dark" ? "#D1D5DB !important" : "#344054 !important",
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "'Inter', sans-serif",
      },
      ".Input::placeholder": {
        fontSize: "14px",
        color: theme === "dark" ? "#9CA3AF" : "#6B7280",
      },
      ".Input:focus": {
        border: "1px solid #A6E500",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      },
      ".TabIcon": {
        color: theme === "dark" ? "#D1D5DB" : "#344054",
      },
      ".Tab": {
        border: theme === "dark" ? "1px solid #323232" : "1px solid #D0D5DD",
        display: "flex",
        fontFamily: "'Inter', sans-serif",
        gap: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        padding: "15px 32px",
        color: theme === "dark" ? "#D1D5DB" : "#344054",
        borderRadius: "8px",
        backgroundColor: theme === "dark" ? "transparent" : "#FFFFFF",
      },
      ".Tab--selected": {
        border: "2px solid #A6E500",
        display: "flex",
        backgroundColor: theme === "dark" ? "transparent" : "#F9FFEA",
        fontFamily: "'Inter', sans-serif",
        gap: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        padding: "15px 32px",
        color: theme === "dark" ? "white" : "#344054",
        borderRadius: "8px",
      },
    },
  };
};
