const currentHost = `${window.location.protocol}//${window.location.hostname}`;

let apiBaseUrl, internalApiBaseUrl, mode, hyperswitchServerUrl, hyperswitchClientUrl;

switch (currentHost) {
    case 'http://localhost:3000':
        apiBaseUrl = process.env.REACT_APP_API_TEST_URL;
        internalApiBaseUrl = process.env.REACT_APP_INTERNAL_API_BASE_URL;
        mode = true; // test mode
        hyperswitchServerUrl = process.env.REACT_APP_HYPERSWITCH_SERVER_TEST_URL;
        hyperswitchClientUrl = process.env.REACT_APP_HYPERSWITCH_CLIENT_TEST_URL;
        break;
    case process.env.REACT_APP_API_TEST_HOST_URL:
        apiBaseUrl = process.env.REACT_APP_API_TEST_URL;
        internalApiBaseUrl = process.env.REACT_APP_INTERNAL_API_BASE_URL;
        mode = true; // test mode
        hyperswitchServerUrl = process.env.REACT_APP_HYPERSWITCH_SERVER_TEST_URL;
        hyperswitchClientUrl = process.env.REACT_APP_HYPERSWITCH_CLIENT_TEST_URL;
        break;
    case process.env.REACT_APP_API_LIVE_HOST_URL:
        apiBaseUrl = process.env.REACT_APP_API_LIVE_URL;
        internalApiBaseUrl = process.env.REACT_APP_INTERNAL_API_BASE_URL;
        mode = false; // live mode
        hyperswitchServerUrl = process.env.REACT_APP_HYPERSWITCH_SERVER_LIVE_URL;
        hyperswitchClientUrl = process.env.REACT_APP_HYPERSWITCH_CLIENT_LIVE_URL;
        break;
    default:
        apiBaseUrl = process.env.REACT_APP_API_TEST_URL;
        internalApiBaseUrl = process.env.REACT_APP_INTERNAL_API_BASE_URL;
        mode = true; // test mode
        hyperswitchServerUrl = process.env.REACT_APP_HYPERSWITCH_SERVER_TEST_URL;
        hyperswitchClientUrl = process.env.REACT_APP_HYPERSWITCH_CLIENT_TEST_URL;
        break;
}

export const API_BASE_URL = apiBaseUrl;
export const INTERNAL_API_BASE_URL = internalApiBaseUrl;
export const MODE = mode; // mode == true means test mode
export const HYPERSWITCH_SERVER_URL = hyperswitchServerUrl;
export const HYPERSWITCH_CLIENT_URL = hyperswitchClientUrl;