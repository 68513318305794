import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { handlePaymentStatus } from "../lib/utils/StatusUtils";

const WalletReturn = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  useEffect(() => {
    if (status && id) {
      handlePaymentStatus(status, id);
    } else return;
  }, [status, id]);
  return (
    <div className="absolute inset-0 w-full h-full flex items-center justify-center">
      Status: {status}
    </div>
  );
};

export default WalletReturn;
