import { z } from "zod";

const querySchema = z.object({
  firstName: z.string().min(2).optional(),
  lastName: z.string().min(2).optional(),
  email: z.string().email().optional(),
  addressLine: z.string().min(5).optional(),
  city: z.string().min(2).optional(),
  country: z.string().length(2).optional(),
  zipCode: z.string().min(5).optional(),
  disableFirstName: z.boolean().optional(),
  disableLastName: z.boolean().optional(),
  disableCountry: z.boolean().optional(),
  disableEmail: z.boolean().optional(),
  disableAddressLine: z.boolean().optional(),
  disableCity: z.boolean().optional(),
  disableZipCode: z.boolean().optional(),
});

export type QueryParams = z.infer<typeof querySchema>;

export const parseQueryParams = (searchParams: URLSearchParams): QueryParams => {
  const params: Record<string, any> = {};

  [
    "firstName",
    "lastName",
    "email",
    "addressLine",
    "city",
    "country",
    "zipCode",
  ].forEach((field) => {
    const value = searchParams.get(field);
    if (value) {
      try {
        // Validate each field using a subset of the schema
        querySchema.shape[field as keyof typeof querySchema.shape].parse(value);
        params[field] = value; // Only include if valid
      } catch (error) {
        console.warn(`Invalid value for ${field}:`, value, (error as Error).message);
      }
    }
  });

  [
    "firstName",
    "lastName",
    "email",
    "addressLine",
    "city",
    "country",
    "zipCode",
  ].forEach((field) => {
    const disableKey = `disable${
      field.charAt(0).toUpperCase() + field.slice(1)
    }`;
    const value = searchParams.get(disableKey);
    if (value === "true") params[disableKey] = true;
  });

  return params;
};
