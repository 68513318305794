// Footer.js
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useTheme } from "../../lib/ThemeContext";

const Footer = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <footer className="flex w-full px-8  flex-col items-center gap-2 py-4">
      <div className="flex w-full items-center justify-center lg:justify-between ">
        {theme !== "dark" ? (
          <img alt="dodopayments logo" src="/dodo_logo.svg" />
        ) : (
          <img alt="dodopayments logo" src="/dodo_logo_dark.svg" />
        )}
        <div className="lg:flex hidden items-center gap-2 text-text-primary font-normal text-[13px]">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dodopayments.com/buyer-terms"
            className="hover:no-underline underline"
          >
            {t("footer.termsAndConditions")}
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dodopayments.com/privacy-policy"
            className="hover:no-underline underline"
          >
            {t("footer.privacyPolicy")}
          </a>
        </div>
      </div>
      <p className="text-text-secondary w-full font-normal text-center lg:text-start text-[13px] leading-5">
        <Trans i18nKey="footer.description">
          This order is processed by our Merchant of Record,{" "}
          <strong>Dodo Payments</strong>
        </Trans>
        <a
          href="https://dodopayments.com"
          target="_blank"
          rel="noreferrer"
          className="hover:no-underline ml-2 underline"
        >
          Learn More
        </a>
      </p>
      <div className="flex lg:hidden items-center gap-2 text-text-primary font-normal text-[13px]">
        <a
          href="https://dodopayments.com/buyer-terms"
          target="_blank"
          rel="noreferrer"
          className="hover:no-underline underline"
        >
          {t("footer.termsAndConditions")}
        </a>
        <a
          href="https://dodopayments.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="hover:no-underline underline"
        >
          {t("footer.privacyPolicy")}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
