import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeSwitch from "./ui/ThemeSwitch";
import { Helmet } from "react-helmet";
import "../App.css";
import Footer from "./ui/Footer";
import { Product } from "../types/types";
import { fetchBusinessName } from "../lib/api/apiServices";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import { Tooltip } from "./ui/Tooltip";
import { Info } from "@phosphor-icons/react";
import TestCard from "./ui/TestCard";

interface ProductCardProps {
  product: Product;
  currency: string;
  isRecurring: boolean;
}

interface PriceSummaryItemProps {
  label: string;
  value: string;
}

interface ProductInfoProps {
  products: Product[];
  currency: string;
  business_id: string;
  tax: number;
  recurring_tax?: number;
  amount: number;
  isRecurring: boolean;
}

interface OrderSummary {
  subtotal: number;
  tax2: number;
  total: number;
}

const ProductCard = ({ product, currency, isRecurring }: ProductCardProps) => {
  const { t } = useTranslation();

  const RecurringPrice = () => (
    <div className="flex flex-col text-nowrap items-start lg:items-end gap-1 w-fit">
      <div className="flex items-start lg:items-center flex-col lg:flex-row justify-end lg:gap-2">
        {product.discount > 0 && (
          <span className="line-through text-start text-text-secondary">
            {t("product.price", {
              price: (product.price / 100).toFixed(2),
              currency,
            })}
          </span>
        )}
        <span className="font-medium font-body text-text-primary text-base">
          {t("product.price", {
            price: (product.price_after_discount / 100).toFixed(2),
            currency,
          })}{" "}
          {t(
            `product.interval.${product.payment_frequency_interval?.toLowerCase()}`,
            { count: product.payment_frequency_count }
          )}
        </span>
      </div>
    </div>
  );

  const OneTimePrice = () => (
    <div className="flex flex-col lg:flex-row items-center gap-1">
      {product.discount !== 0 ? (
        <>
          <span className="line-through text-text-secondary">
            {t("product.price", {
              price: (product.price / 100).toFixed(2),
              currency,
            })}
          </span>
          <span className="text-text-primary">
            {t("product.price", {
              price: (product.price_after_discount / 100).toFixed(2),
              currency,
            })}
          </span>
        </>
      ) : (
        <span className="font-medium font-body text-text-primary text-base">
          {t("product.price", {
            price: (product.price_after_discount / 100).toFixed(2),
            currency,
          })}
        </span>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-3 items-start">
      <div className="flex gap-4 lg:gap-6 w-full items-start">
        <div className="aspect-square rounded-lg w-[120px] h-[120px] relative">
          <img
            alt={product.name}
            src={product.image}
            className="object-cover rounded-lg"
          />
        </div>
        <div className="flex lg:flex-row flex-col items-start justify-between w-full">
          <div className="flex flex-col w-full gap-2">
            <h2 className="font-display text-xl font-medium text-text-primary">
              {product.name}
            </h2>
            <span className="text-text-primary font-medium text-sm">
              Quantity: {product.quantity}
            </span>
            <p className="text-text-secondary hidden break-words max-w-[25vw] lg:block text-sm font-normal text-wrap">
              {product.description}
            </p>
          </div>
          {isRecurring ? <RecurringPrice /> : <OneTimePrice />}
        </div>
      </div>
      <p className="text-text-secondary break-words max-w-[60vw] lg:hidden text-sm font-normal text-wrap">
        {product.description}
      </p>
    </div>
  );
};

const PriceSummaryItem: React.FC<PriceSummaryItemProps> = ({
  label,
  value,
}) => (
  <div className="flex items-center justify-between">
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

const ProductInfo: React.FC<ProductInfoProps> = ({
  products = [],
  currency = "USD",
  amount = 0,
  tax = 0,
  recurring_tax = 0,
  business_id,
  isRecurring = false,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState<{
    name: string;
    image: string;
  }>({ name: "", image: "" });

  const orderSummary = useMemo<OrderSummary>(() => {
    const subtotal = amount - tax;
    const tax2 = isRecurring ? recurring_tax : tax;
    const total = isRecurring ? amount + recurring_tax : amount;
    return { subtotal, tax2, total };
  }, [amount, tax, isRecurring, recurring_tax]);

  useEffect(() => {
    const GetBusinessName = async () => {
      try {
        const data = await fetchBusinessName(business_id || "");
        setBusinessName(data);
      } catch (error) {
        console.error("Error loading product details:", error);
      } finally {
      }
    };
    GetBusinessName();
  }, [business_id]);

  if (!products.length) {
    return <div>No products available</div>;
  }

  return (
    <section className="lg:h-screen flex flex-col lg:overflow-hidden">
      <Helmet>
        <title>{`${
          businessName.name
            ? `${
                businessName.name.charAt(0).toUpperCase() +
                businessName.name.slice(1)
              } - Checkout`
            : "Dodopayments - Checkout"
        }`}</title>
      </Helmet>
      <header className="flex mx-4 lg:mx-8  pt-8 justify-between py-4 ">
        <div className="text-text-primary flex items-center gap-2 text-2xl pt-2 capitalize font-display font-medium">
          <Avatar>
            <AvatarImage src={businessName.image} alt={businessName.name} />
            <AvatarFallback name={businessName.name} singleInitials={true} />
          </Avatar>
          <span>{businessName.name}</span>
        </div>
        <ThemeSwitch />
      </header>

      <div className="flex-grow p-4 lg:p-8 lg:pt-2 overflow-auto">
        <div className="">
          <div className="flex flex-col gap-8 mb-8">
            {products.map((product) => (
              <ProductCard
                key={product.product_id}
                product={product}
                currency={currency}
                isRecurring={isRecurring}
              />
            ))}
          </div>
          {isRecurring && (
            <div className="border-t font-medium py-2 text-text-primary  border-border-primary flex items-center justify-between">
              <div className="flex items-center gap-3">
                <span>Amount due now</span>
                <Tooltip
                  align="start"
                  content={
                    <ul className="list-disc pl-4">
                      <li>
                        $0 Payment mandate will be created, once payment details
                        are processed successfully.
                      </li>
                      <li>
                        Amount will be deducted anytime on the next payment
                        date. Please ensure sufficient funds for smooth
                        transaction processing.
                      </li>
                      <li>
                        Total amount deducted may vary slightly as per tax rate
                        at the time of deduction.
                      </li>
                      <li>
                        Detailed invoice will be sent to your email id once
                        transaction is completed so ensure email id entered is
                        accurate.
                      </li>
                    </ul>
                  }
                >
                  <Info className="w-5 h-5 cursor-pointer" />
                </Tooltip>
              </div>
              <div>$0</div>
            </div>
          )}
          <div className="flex flex-col border-t py-4 border-border-primary font-normal text-sm text-text-secondary pb-4 gap-4">
            {isRecurring && products[0].trial_period_days !== undefined && (
              <PriceSummaryItem
                label="First payment date"
                value={new Date(
                  new Date().getTime() +
                    products[0].trial_period_days * 24 * 60 * 60 * 1000
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              />
            )}
            <PriceSummaryItem
              label={t("orderSummary.subtotal")}
              value={t("product.price", {
                price: (orderSummary.subtotal / 100).toFixed(2),
                currency,
              })}
            />

            <PriceSummaryItem
              label={t("orderSummary.tax")}
              value={t("product.price", {
                price: (orderSummary.tax2 / 100).toFixed(2),
                currency,
              })}
            />
          </div>

          <div className="flex border-y py-4 border-border-primary text-base font-medium text-text-primary items-center justify-between">
            <span>
              {isRecurring
                ? "Amount due on First Payment"
                : t("orderSummary.total")}
            </span>
            <span>
              {t("product.price", {
                price: (orderSummary.total / 100).toFixed(2),
                currency,
              })}
            </span>
          </div>

          <TestCard />
        </div>
      </div>
              
      <div className="hidden pt-4 lg:block">
        <Footer />
      </div>
    </section>
  );
};

export default ProductInfo;
