/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { HyperElements } from "@juspay-tech/react-hyper-js";
import { useParams } from "react-router-dom";
import "../i18n";
import "../App.css";
import CheckoutForm from "../components/CheckoutForm";
import ProductInfo from "../components/ProductInfo";
import Footer from "../components/ui/Footer";
import { useTheme } from "../lib/ThemeContext";
import LoadingOverlay from "../components/ui/Loading";
import { fetchProductDetails } from "../lib/api/apiServices";
import { getAppearanceConfig } from "../lib/utils/ThemeUtils";
import { handlePaymentStatus } from "../lib/utils/StatusUtils";
import { calculateRemainingTime, formatRemainingTime } from "../lib/utils/TimeUtils";
import { ProductData } from "../types/types";
import Banner from "../components/banner";
import { HYPERSWITCH_CLIENT_URL, HYPERSWITCH_SERVER_URL } from "../lib/constants/api";

declare global {
  interface Window {
    Hyper: any;
  }
}

function useProductData(id: string | undefined) {
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<ProductData | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [publishableKey, setPublishableKey] = useState<string | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    if (!id) return;

    const loadProductDetails = async () => {
      setLoading(true);
      try {
        const data = await fetchProductDetails(id);
        setClientSecret(data.secret_key);
        setPublishableKey(data.publishable_key);
        setProductData(data);

        const expirationTime = new Date(data.expires_on).getTime();
        const timeLeft = calculateRemainingTime(expirationTime);
        setRemainingTime(timeLeft > 0 ? timeLeft : 0);

        await handlePaymentStatus(data.status, id);
      } catch (error) {
        console.error("Error loading product details:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProductDetails();
  }, [id]);

  return { loading, productData, clientSecret, publishableKey, remainingTime, setRemainingTime };
}

function useHyperSDK(publishableKey: string | null) {
  const [hyperPromise, setHyperPromise] = useState<any>(null);

  useEffect(() => {
    if (!publishableKey) return;

    const loadHyperSDK = () => {
      const script = document.createElement("script");
      script.src = HYPERSWITCH_CLIENT_URL || "";
      document.head.appendChild(script);
      script.onload = () => {
        setHyperPromise(
          new Promise((resolve) => {
            resolve(
              window.Hyper(publishableKey, {
                customBackendUrl: HYPERSWITCH_SERVER_URL,
              })
            );
          })
        );
      };
      script.onerror = () => {
        console.error("Error loading Hyper SDK script");
      };

      return () => {
        document.head.removeChild(script);
      };
    };

    loadHyperSDK();
  }, [publishableKey]);

  return hyperPromise;
}

function useThemeChangeHandler(theme: string) {
  const prevThemeRef = useRef<string>();

  useEffect(() => {
    if (prevThemeRef.current && prevThemeRef.current !== theme) {
      console.log("Theme changed, reloading page");
      window.location.reload();
    }
    prevThemeRef.current = theme;
  }, [theme]);
}

function useCountdown(remainingTime: number | null, setRemainingTime: React.Dispatch<React.SetStateAction<number | null>>) {
  useEffect(() => {
    if (remainingTime && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => (prevTime !== null ? Math.max(prevTime - 1000, 0) : 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [remainingTime, setRemainingTime]);

  if (remainingTime !== null && remainingTime <= 0) {
    window.location.href = "/status/link-expired";
  }
}


const CheckoutPage: React.FC = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const { theme } = useTheme();
  const { loading, productData, clientSecret, publishableKey, remainingTime, setRemainingTime } = useProductData(id);
  const hyperPromise = useHyperSDK(publishableKey);
  useThemeChangeHandler(theme);
  useCountdown(remainingTime, setRemainingTime);

  const options = {
    clientSecret,
    appearance: getAppearanceConfig(theme),
  };

  if (loading) return <LoadingOverlay />;

  return (
    <div className="app flex font-body lg:flex-row flex-col w-[100vw] h-screen">
      <Banner />
      <section className="lg:w-1/2 lg:overflow-auto scrollable-element bg-background-primary lg:bg-background-secondary border-r border-border-secondary flex justify-between flex-col">
        {productData && (
          <ProductInfo
            products={productData.product_cart}
            business_id={productData.business_id}
            currency={productData.currency}
            tax={productData.tax}
            recurring_tax={productData.expected_tax_on_recurring_payments}
            amount={productData.is_recurring ? productData.recurring_amount || 0 : productData.amount}
            isRecurring={productData.is_recurring}
          />
        )}
      </section>
      <section className="flex lg:flex-row flex-col py-10 lg:min-h-screen lg:w-1/2 justify-center items-center bg-background-primary">
        {clientSecret && hyperPromise && (
          <div className="flex flex-col h-fit lg:h-full items-center justify-center mt-0 lg:mt-0">
            <div className="w-fit h-fit p-5 shadow-lg rounded-xl border bg-background-primary border-border-primary">
              <HyperElements  options={options} hyper={hyperPromise}>
                <CheckoutForm />
              </HyperElements>
            </div>
            <div className="mt-4 px-3 text-center text-text-secondary text-base">
              {formatRemainingTime(remainingTime || 0)}
            </div>
          </div>
        )}
        <div className="block lg:hidden">
          <Footer />
        </div>
      </section>
    </div>
  );
};

export default CheckoutPage;
