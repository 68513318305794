import React, { useState } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  side = "top",
  align = "center",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInteractionStart = (e: React.TouchEvent | React.MouseEvent) => {
    setIsOpen(true);
  };

  const handleInteractionEnd = () => {
    setIsOpen(false);
  };

  return (
    <TooltipPrimitive.Provider delayDuration={200}>
      <TooltipPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
        <TooltipPrimitive.Trigger 
          asChild
          onTouchStart={handleInteractionStart}
          onMouseEnter={handleInteractionStart}
          onTouchEnd={handleInteractionEnd}
          onMouseLeave={handleInteractionEnd}
        >
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className="z-50 rounded-lg bg-background-primary text-text-primary border border-border-primary 
                       px-3 py-2 text-sm shadow-lg max-w-md text-wrap
                       will-change-[transform,opacity] 
                       data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade
                       data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade
                       data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade
                       data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
            side={side}
            align={align}
          >
            {content}
            <TooltipPrimitive.Arrow className="fill-background-primary" />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};