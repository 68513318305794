import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useHyper,
  useWidgets,
} from "@juspay-tech/react-hyper-js";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface PaymentElementOptions {
  layout: string;
  displaySavedPaymentMethodsCheckbox: boolean;
  wallets: {
    walletReturnUrl: string;
    payPal: string;
    googlePay: string;
    style: {
      theme: string;
      height: number;
      type: string;
      buttonRadius: number;
    };
  };
  branding: string;
}

interface ConfirmPaymentResponse {
  status: string;
  error?: {
    message: string;
  };
}

const CheckoutForm: React.FC = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const { t } = useTranslation();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hyper = useHyper();
  const widgets = useWidgets();

  const paymentElementOptions: PaymentElementOptions = {
    layout: "tabs",
    displaySavedPaymentMethodsCheckbox: false,
    wallets: {
      walletReturnUrl: `${window.location.origin}/${id}/walletReturn`,
      payPal: "auto",
      googlePay: "auto",
      style: {
        theme: "dark",
        type: "default",
        height: 40,
        buttonRadius: 8,
      },
    },
    branding: "never",
  };

  const handlePaymentStatus = React.useCallback(
    (status: string) => {
      switch (status) {
        case "succeeded":
          setMessage(t("checkout.paymentSucceeded"));
          break;
        case "processing":
          setMessage(t("checkout.paymentProcessing"));
          break;
        case "requires_payment_method":
        case "failed":
          setMessage(t("checkout.paymentFailedMessage"));
          break;
        default:
          setMessage(t("checkout.somethingWentWrong"));
          break;
      }
    },
    [t]
  );

  useEffect(() => {
    if (!hyper) {
      return;
    }

    const paymentID = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!paymentID) {
      return;
    }

    hyper
      .retrievePaymentIntent(paymentID)
      .then(({ paymentIntent }: { paymentIntent: any }) => {
        if (paymentIntent?.status) {
          handlePaymentStatus(paymentIntent.status);
        }
      });
  }, [hyper, handlePaymentStatus]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hyper || !widgets) {
      return;
    }

    setIsLoading(true);

    const response: ConfirmPaymentResponse = await hyper.confirmPayment({
      widgets,
      confirmParams: {
        return_url: `${window.location.origin}/${id}/walletReturn`,
      },
      redirect: "if_required",
    });

    if (response) {
      handlePaymentStatus(response.status);
      if (response.status === "succeeded") {
        window.location.href = `/${id}/status/complete`;
      } else if (response.status === "processing") {
        window.location.href = `/${id}/status/processing`;
      } else if (response.status === "failed") {
        window.location.href = `/${id}/status/failed`;
      } else if (response.error) {
        setMessage(response.error.message);
      }
    } else {
      setMessage(t("checkout.unexpectedError"));
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element"  options={paymentElementOptions} />
      <button
        id="submit"
        className="bg-button-primary-bg mt-5 mb-1 flex items-center justify-center text-button-primary-text dark:text-black hover:bg-button-primary-hover hover:text-black font-display rounded-lg border border-transparent hover:border-border-secondary transition-colors ease-in-out duration-300"
        disabled={isLoading}
      >
        <span id="button-text">
          {isLoading ? <>Loading...</> : t("checkout.payNow")}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default CheckoutForm;
