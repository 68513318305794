import React from "react";
import { useTheme } from "../../lib/ThemeContext";
import { useTranslation } from "react-i18next";

const LinkExpiredPage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <main className="w-[100vw] font-body h-screen bg-background-primary lg:bg-background-secondary flex items-center justify-center">
      <div className="bg-background-primary z-50 max-w-sm rounded-xl shadow-none lg:shadow-md border-none lg:border border-border-primary flex flex-col items-center pt-14 px-8 pb-6">
        <div className="flex flex-col items-center gap-6 mb-3">
          <img src="/LinkBreak.svg" alt="Link Break" />
          <span className="font-display text-center font-semibold text-2xl text-text-primary">
            {t("LinkExpiredPage.title")}
          </span>
        </div>
        <div className="flex flex-col border-b lg:border-none pb-8 border-border-primary items-center gap-6">
          <p className="font-body text-wrap text-text-secondary text-sm font-normal text-center">
            {t("LinkExpiredPage.description")}
          </p>
          <div className="flex font-body text-button-clink-default text-sm font-medium text-center flex-col items-center">
            {/* <span>{t("LinkExpiredPage.redirect_message")}</span>
            <a href="/" className="underline">
              {t("LinkExpiredPage.redirect_link_text")}
            </a> */}
          </div>
        </div>
        <div className="flex items-center mt-4 gap-1">
          <span className="text-text-secondary text-[13px] font-normal">
            {t("LinkExpiredPage.powered_by")}
          </span>
          <img
            alt="dodopayments logo"
            src={theme !== "dark" ? "/dodo_logo.svg" : "/dodo_logo_dark.svg"}
          />
        </div>
      </div>
    </main>
  );
};

export default LinkExpiredPage;
