import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n";
import { ThemeProvider } from "./lib/ThemeContext";
import LinkExpiredPage from "./pages/StatusPages/linkExpired";
import CheckoutPage from "./pages/Checkout";
import BuyPage from "./pages/Buy";
import UnifiedStatusPage from "./pages/StatusPages/Status";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import PostHogPageviewTracker from "./lib/PostHogPageviewTracker";
import WalletReturn from "./pages/WalletReturn";

if (process.env.REACT_APP_NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  });
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_API_HOST,
    ui_host: process.env.REACT_APP_POSTHOG_UI_HOST,
    person_profiles: "identified_only",
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ThemeProvider>
        <BrowserRouter>
          <PostHogPageviewTracker />
          <Routes>
            <Route path="/:id" element={<CheckoutPage />} />
            <Route path="/buy/:product_id" element={<BuyPage />} />
            <Route path="/:id/walletReturn" element={<WalletReturn />} />
            <Route path="/:id/status/:status" element={<UnifiedStatusPage />} />
            <Route path="/status/link-expired" element={<LinkExpiredPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>
);
