// statusUtils.js

export const handlePaymentStatus = async (status, id) => {
  switch (status) {
    case "succeeded":
      window.location.href = `/${id}/status/complete`;
      await new Promise((resolve) => setTimeout(resolve, 10000));
      break;
    case "processing":
      console.log("Payment is still processing");
      window.location.href = `/${id}/status/processing`;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      break;
    case "requires_payment_method":
      console.log("Payment requires a payment method");

      break;
    case "requires_confirmation":
      console.log("Payment requires confirmation");

      break;
    case "failed":
      console.log("Payment was canceled");
      window.location.href = `/${id}/status/failed`;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      break;
    default:
      console.log(`Unhandled payment status: ${status}`);
      await new Promise((resolve) => setTimeout(resolve, 1000));
  }
};
