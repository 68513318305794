// CustomerForm.tsx
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { PhoneInput } from "react-international-phone";
import { useSearchParams } from "react-router-dom";
import { PaymentDetails } from "../types/types";
import { COUNTRIES } from "./ui/CountrySelector/countries";
import CountrySelector from "./ui/CountrySelector/selector";
import { SelectMenuOption } from "./ui/CountrySelector/types";

import Loading from "./ui/LoadingSpinner";

import "react-international-phone/style.css";
import "../styles/CustomerForm.css";
import { parseQueryParams } from "../lib/QuerryCollector";
import toast from "react-hot-toast";

const formSchema = z.object({
  firstName: z.string().min(2, "First name must be at least 2 characters"),
  lastName: z.string().min(2, "Last name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  country: z.string().min(2, "Country must be at least 2 characters"),
  addressLine: z.string().min(5, "Address must be at least 5 characters"),
  city: z.string().min(2, "City must be at least 2 characters"),
  zipCode: z.string().min(5, "Zip code must be at least 5 characters"),
  phoneNumber: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

interface CustomerFormProps {
  productData: {
    is_recurring: boolean;
    product_id: string;
  };
  onSubmit: (
    paymentDetails: PaymentDetails,
    setFormLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
}

const DEFAULT_COUNTRY = "IN";

const CustomerForm: React.FC<CustomerFormProps> = ({
  productData,
  onSubmit,
}) => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(DEFAULT_COUNTRY);
  const [phoneInputMeta, setPhoneInputMeta] = React.useState<{
    country: any;
    inputValue: string;
  } | null>(null);

  // Parse query parameters
  const queryParams = React.useMemo(
    () => parseQueryParams(searchParams),
    [searchParams]
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: queryParams.firstName || "",
      lastName: queryParams.lastName || "",
      email: queryParams.email || "",
      country: DEFAULT_COUNTRY,
      addressLine: queryParams.addressLine || "",
      city: queryParams.city || "",
      zipCode: queryParams.zipCode || "",
      phoneNumber: "",
    },
  });

  const createPaymentDetails = (formData: FormData): PaymentDetails => {
    const quantity = parseInt(searchParams.get("quantity") || "1", 10);
    const redirect_url = searchParams.get("redirect_url") || null;

    const billingInfo = {
      city: formData.city,
      country: formData.country,
      state: "",
      street: formData.addressLine,
      zipcode: parseInt(formData.zipCode, 10),
    };

    const customerInfo = {
      email: formData.email,
      name: `${formData.firstName} ${formData.lastName}`,
      phone_number: formData.phoneNumber || null,
    };

    return productData.is_recurring
      ? {
          CreateSubscriptionRequest: {
            billing: billingInfo,
            customer: customerInfo,
            payment_link: true,
            product_id: productData.product_id,
            quantity,
            return_url: redirect_url,
          },
        }
      : {
          CreateOneTimePaymentRequest: {
            billing: billingInfo,
            customer: customerInfo,
            payment_link: true,
            product_cart: [
              {
                product_id: productData.product_id,
                quantity,
              },
            ],
            return_url: redirect_url,
          },
        };
  };

  const handleFormSubmit = async (formData: FormData) => {
    setLoading(true);

    try {
      // Validate phone number if provided
      if (formData.phoneNumber) {
        const phoneValue = phoneInputMeta?.inputValue || "";
        const hasOnlyCountryCode =
          phoneValue.trim() === `+${phoneInputMeta?.country.dialCode}`;

        if (hasOnlyCountryCode) {
          delete formData.phoneNumber;
        } else if (
          phoneValue.length < (phoneInputMeta?.country.format.length || 0)
        ) {
          toast.error("Please enter a complete phone number");
          return;
        }
      }

      const paymentDetails = createPaymentDetails(formData);
      await onSubmit(paymentDetails, setLoading);
    } catch (error) {
      toast.error("Failed to process payment details. Please try again.");
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("country", selectedCountry);
  }, [selectedCountry, setValue]);

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="lg:p-8 p-4 lg:px-[72px] h-fit font-body w-full"
    >
      <h2 className="text-base font-medium mb-3 font-display text-text-primary">
        Enter your information
      </h2>

      {/* Name Fields */}
      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="firstName" className="form-label">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            {...register("firstName")}
            type="text"
            id="firstName"
            placeholder="eg: John"
            className="form-input"
            disabled={queryParams.disableFirstName}
          />
          {errors.firstName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="flex-1">
          <label htmlFor="lastName" className="form-label">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            {...register("lastName")}
            type="text"
            id="lastName"
            placeholder="eg: Doe"
            className="form-input"
            disabled={queryParams.disableLastName}
          />
          {errors.lastName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.lastName.message}
            </p>
          )}
        </div>
      </div>

      {/* Email Field */}
      <div className="mb-4">
        <label htmlFor="email" className="form-label">
          Email <span className="text-red-500">*</span>
        </label>
        <input
          {...register("email")}
          type="email"
          id="email"
          placeholder="eg: johndoe@example.com"
          className="form-input"
          disabled={queryParams.disableEmail}
        />
        {errors.email && (
          <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>
        )}
      </div>

      {/* Country Selector */}
      <div className="mb-4">
        <label htmlFor="country" className="form-label">
          Country
        </label>
        <Controller
          name="country"
          control={control}
          defaultValue={selectedCountry}
          render={({ field: { onChange } }) => (
            <CountrySelector
              id="country-selector"
              open={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              onChange={(val) => {
                setSelectedCountry(val);
                onChange(val);
              }}
              selectedValue={
                COUNTRIES.find(
                  (option) => option.value === selectedCountry
                ) as SelectMenuOption
              }
              disabled={queryParams.disableCountry}
            />
          )}
        />
        {errors.country && (
          <p className="text-red-600 text-sm mt-1">{errors.country.message}</p>
        )}
      </div>

      {/* Address Fields */}
      <div className="mb-4">
        <label htmlFor="addressLine" className="form-label">
          Address line
        </label>
        <input
          {...register("addressLine")}
          type="text"
          id="addressLine"
          placeholder="Eg: 364 Kent St"
          className="form-input"
          disabled={queryParams.disableAddressLine}
        />
        {errors.addressLine && (
          <p className="text-red-600 text-sm mt-1">
            {errors.addressLine.message}
          </p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="city" className="form-label">
            City
          </label>
          <input
            {...register("city")}
            type="text"
            id="city"
            placeholder="Sydney"
            className="form-input"
            disabled={queryParams.disableCity}
          />
          {errors.city && (
            <p className="text-red-600 text-sm mt-1">{errors.city.message}</p>
          )}
        </div>

        <div className="flex-1">
          <label className="form-label">Zipcode</label>
          <input
            {...register("zipCode")}
            type="number"
            id="zipCode"
            placeholder="Eg: 2035"
            className="form-input"
            disabled={queryParams.disableZipCode}
          />
          {errors.zipCode && (
            <p className="text-red-600 text-sm mt-1">
              {errors.zipCode.message}
            </p>
          )}
        </div>
      </div>

      {/* Phone Number Field */}
      <div className="mb-4 w-full">
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number (optional)
        </label>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              value={value}
              className="shadow-sm z-10"
              onChange={(phone, meta) => {
                onChange(phone);
                setPhoneInputMeta(meta);
              }}
              defaultCountry="in"
              countrySelectorStyleProps={{
                flagClassName: "px-1",
                buttonClassName:
                  "border border-[#d0d5dd] rounded-l-lg px-2 bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                dropdownStyleProps: {
                  listItemClassName:
                    "px-2 bg-[#ffffff] dark:bg-[#0d0d0d] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                  className:
                    "rounded-lg bg-[#ffffff] border dark:border-[#323232] border-[#d0d5dd] dark:bg-[#0d0d0d] text-text-primary dark:text-[#ffffff]",
                },
              }}
              inputProps={{
                className:
                  "w-full font-body text-sm font-normal border border-[#d0d5dd] bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] px-2 rounded-r-lg text-text-primary dark:text-white " +
                  "focus:outline-none focus:ring-2 focus:ring-[#a6e500] dark:focus:ring-[#a6e500] focus:border-transparent",
              }}
            />
          )}
        />
        {errors.phoneNumber && (
          <p className="text-red-600 text-sm mt-1">
            {errors.phoneNumber.message}
          </p>
        )}
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full flex items-center justify-center py-2 px-4 font-normal 
                 hover:text-black border border-transparent hover:border-border-secondary 
                 mt-6 bg-button-primary-bg dark:text-black font-display text-white 
                 rounded-lg shadow-sm hover:bg-accent focus:outline-none 
                 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        disabled={loading}
      >
        {loading ? <Loading /> : "Continue to Payment"}
      </button>
    </form>
  );
};

export default CustomerForm;
