export const calculateRemainingTime = (expirationTime) => {
  const currentTime = Date.now();
  return expirationTime - currentTime;
};

export const formatRemainingTime = (remainingTime) => {
  if (remainingTime <= 0) {
    return "This session has expired.";
  }
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  return `Your payment session will expire in ${minutes} minutes and ${seconds} seconds.`;
};